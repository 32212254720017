<template>
  <b-card>
    <b-card-title class="d-flex justify-content-between align-items-center">
      <h4>Add Certificate Items</h4>
      <Button type="primary" @click="certificateModal(true)">Add</Button>
    </b-card-title>
    <hr>
    <app-collapse accordion>
      <app-collapse-item :title="item.name" v-for="(item, index) in form.items" :key="index" v-if="form.items.length">
        <b-row class="justify-content-center">
          <b-col cols="6">
            <div class="form-group">
              <label> Top (px)</label>
              <InputNumber
                  v-model="item.top"
                  placeholder="Enter Top in pixel"
                  style="width: 100%"
                  type="number"
              />
            </div>
          </b-col>
          <b-col cols="6">
            <div class="form-group">
              <label> Left (px)</label>
              <InputNumber
                  v-model="item.left"
                  placeholder="Enter Left in pixel"
                  style="width: 100%"
                  type="number"
              />
            </div>
          </b-col>
          <b-col cols="6">
            <div class="form-group">
              <label> Width (px)</label>
              <Input
                  v-model="item.width"
                  placeholder="Enter Width"
                  style="width: 100%"
                  type="number"
              />
            </div>

          </b-col>
          <b-col cols="6">
            <div class="form-group">
              <label> Font Size (px)</label>
              <Input
                  v-model="item.font_size"
                  placeholder="Enter Font Size"
                  style="width: 100%"
                  type="number"
              />
            </div>
          </b-col>
          <b-col cols="6">
            <div class="form-group">
              <label> Font Weight</label>
              <Select v-model="item.font_weight" filterable>
              <Option value="regular"> Regular </Option>
              <Option value="bold"> Bold </Option>
              <Option value="bolder"> Bolder </Option>
              </Select>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="form-group">
              <label>Pick Color</label>
              <Input
                  v-model="item.color"
                  type="color"
                  style="width: 100%; padding: 0; border: none; height: 2rem;"
              />
            </div>

          </b-col>
          <b-col cols="6">
            <div class="form-group">
              <label> Line Height</label>
              <Input
                  v-model="item.line_height"
                  placeholder="Enter Line Height"
                  style="width: 100%"
                  type="text"
              />
            </div>
          </b-col>
          <b-col cols="6">
            <div class="form-group">
              <label> Text Align</label>
              <Select v-model="item.text_align" filterable>
              <Option value="left"> Left </Option>
              <Option value="center"> Center </Option>
              <Option value="right"> Right </Option>
              </Select>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="form-group">
              <label> Data </label>
              <Input
                  v-model="item.data"
                  placeholder="Enter data"
                  style="width: 100%"
                  :type="item.type == 2 ? 'textarea' : 'text'"
                  :rows="4"
              />
            </div>
            <div v-if="item.type == 2">
              <mark>
                Example: <br>
                <b>Variables:</b> <code>{{ '{' + '{gender}' + '}' }}</code> of <code>{{ '{' + '{fathers_name}' + '}' }}</code> and <code>{{ '{' + '{mothers_name}' + '}' }}.</code>
                <br>
                <b>Bold:</b> <code>{{ '**{' + '{course_name}' + '}**' }}</code>,<code>{{ '{' + '{**institute_name**}' + '}' }}</code>
              </mark>
            </div>
          </b-col>`

          <b-col cols="12">
            <div>
              <b-button
                  class="btn btn-danger btn-sm"
                  @click="() => deleteItem(index)"
              >
                <feather-icon icon="TrashIcon"></feather-icon>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>

    <b-button
        v-if="permission.create"
        variant="primary"
        type="submit"
        :disabled="form.busy"
        class="float-right"
        @click="templateGenerate"
    >
      Save
    </b-button>

  </b-card>
</template>

<script>
import {BButton, BCard, BCardTitle, BCol, BRow} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {AppCollapseItem, BCardTitle, BRow, BCard, BButton, AppCollapse, BCol},
  props: {
    permission: Object,
    form: Object,
    deleteItem: Function,
    certificateModal: Function,
    templateGenerate: Function,
  },
};
</script>
