<template>
  <Modal
      title="Certificate Item"
      v-model="certificate_model"
      :footer-hide="true"
      :mask-closable="false"
      :closable="false"
  >
    <div class="form-group">
      <label>Meta Data (if any)</label>
      <Select
          v-model="certificate_item.meta_data_id"
          filterable
          @on-change="SelectMetaOption()"
          :clearable="true"
      >
      <Option
          v-if="formatMetaData.length"
          v-for="metaData in formatMetaData"
          :value="metaData.id"
          :key="metaData.id"
      >{{ metaData.name }}
      </Option
      >
      </Select>
    </div>

    <div class="form-group">
      <label> Name </label>
      <Input
          v-model="certificate_item.name"
          placeholder="Enter name"
          style="width: 100%"
          type="text"
      />
    </div>

    <div class="form-group" v-if="!certificate_item.meta_data_id">
      <label> Type </label>
      <Select v-model="certificate_item.type" filterable :clearable="true">
      <Option :value="1"> Meta Data</Option>
      <Option :value="2"> Text Paragraph</Option>
      <Option :value="3"> Fixed Data</Option>
      </Select>
    </div>

    <div class="d-flex justify-content-between align-items-center mt-2">
      <div>
        <b-button @click="() => certificateModal(false)" variant="danger">
          Cancel
        </b-button>
      </div>
      <div>
        <b-button @click="AddCertificate()" variant="primary" type="submit">
          Ok
        </b-button>
      </div>
    </div>
  </Modal>
</template>

<script>
import {BButton} from "bootstrap-vue";

export default {
  components: {BButton},
  props: {
    certificate_model: Boolean,
    certificate_item: Object,
    formatMetaData: Array,
    certificateModal: Function,
    SelectMetaOption: Function,
    AddCertificate: Function,
    clearModalData: Function,
  },
};
</script>
