<template>
  <b-card title="Certificate Preview">
    <hr>
    <div style="overflow-x: scroll">
      <div style="width: 1123px; height: 794px; margin: auto; position: relative">
        <div style="position: absolute; top: 0px; left: 0px; z-index: 1; width: 100%; height: 100%">
          <div class="certificate_default"
               v-for="(item, index) in form.items"
               v-if="form.items.length"
               :key="index"
               :style="{
                              top: (item.top || 0) + 'px',
                              left: (item.left || 0) + 'px',
                              fontSize: (item.font_size || 14) + 'px',
                              fontWeight: (item.font_weight || 'normal'),
                              color: item.color || 'black' ,
                              lineHeight: (item.line_height || ((item.font_size || 14) * 1.5)) + 'px',
                              textAlign: item.text_align,
                              width: item.width ? item.width + 'px' : (1123 - item.left) + 'px'
                          }"
          >
            {{ item.data }}
          </div>
        </div>
        <img
            style="position: absolute; top: 0px; left: 0;"
            :src="format.background_file || getImage(1123, 794)"
            width="100%"
            height="100%"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import {BCard} from "bootstrap-vue";

export default {
  components: {BCard},
  props: {
    form: Object,
    format: Object,
  },
};
</script>

<style scoped>
.certificate_default {
  position: absolute;
}
</style>
