var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Certificate Preview"}},[_c('hr'),_c('div',{staticStyle:{"overflow-x":"scroll"}},[_c('div',{staticStyle:{"width":"1123px","height":"794px","margin":"auto","position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"0px","z-index":"1","width":"100%","height":"100%"}},_vm._l((_vm.form.items),function(item,index){return (_vm.form.items.length)?_c('div',{key:index,staticClass:"certificate_default",style:({
                            top: (item.top || 0) + 'px',
                            left: (item.left || 0) + 'px',
                            fontSize: (item.font_size || 14) + 'px',
                            fontWeight: (item.font_weight || 'normal'),
                            color: item.color || 'black' ,
                            lineHeight: (item.line_height || ((item.font_size || 14) * 1.5)) + 'px',
                            textAlign: item.text_align,
                            width: item.width ? item.width + 'px' : (1123 - item.left) + 'px'
                        })},[_vm._v(" "+_vm._s(item.data)+" ")]):_vm._e()}),0),_c('img',{staticStyle:{"position":"absolute","top":"0px","left":"0"},attrs:{"src":_vm.format.background_file || _vm.getImage(1123, 794),"width":"100%","height":"100%"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }