<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-3">
        <CertificateItems
            :permission="permission"
            :form="form"
            :certificateModal="certificateModal"
            :templateGenerate="templateGenerate"
            :deleteItem="deleteItem"
        />
      </div>
      <div class="col-md-9">
        <CertificatePreview
            :form="form"
            :format="format"
            :certificate_model="certificate_model"
        />
      </div>
    </div>
    <CertificateItemModal
        :certificate_model="certificate_model"
        :certificate_item="certificate_item"
        :formatMetaData="formatMetaData"
        :certificateModal="certificateModal"
        :SelectMetaOption="SelectMetaOption"
        :AddCertificate="AddCertificate"
        :clearModalData="clearModalData"
    />
  </div>
</template>

<script>
import CertificateItems from "@/views/template/partials/CertificateItems.vue";
import CertificatePreview from "@/views/template/partials/CertificatePreview.vue";
import CertificateItemModal from "@/views/template/partials/CertificateItemModal.vue";

import {
  BCard,
  BCardBody,
  BCardTitle,
  BRow,
  BCol,
  BFormCheckbox,
  BForm,
  BButton,
  BFormFile,
} from "bootstrap-vue";
import {HasError} from "vform/src/components/bootstrap5";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import _ from "lodash";

export default {
  data() {
    return {
      certificate_item: {
        name: null,
        meta_data_id: null,
        type: null,
      },
      form: new Form({
        items: []
      }),
      certificate_model: false,
      formatMetaData: [],
      format: {},
      id: this.$route.params.format_id,
      file: null,
    };
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AppCollapse,
    BCardTitle,
    AppCollapseItem,
    BFormFile,
    CertificateItems,
    CertificatePreview,
    CertificateItemModal,
  },
  mounted() {
    this.getFormat();
    this.getMetaData();
    this.getTemplateItems();
  },
  methods: {
    SelectMetaOption() {
      if (this.certificate_item.meta_data_id) {
        let meta_data_index = this.formatMetaData.findIndex(meta => meta.id == this.certificate_item.meta_data_id);
        this.certificate_item.name = this.certificate_item.name || this.formatMetaData[meta_data_index].name
      }
    },
    AddCertificate() {
      if (this.certificate_item.name == null) {
        return this.i("Name field is required");
      }

      if (this.certificate_item.type == null && this.certificate_item.meta_data_id == null) {
        return this.i("Type field is required");
      }

      let meta_data_index = null;
      if (this.certificate_item.meta_data_id) {
        meta_data_index = this.formatMetaData.findIndex(meta => meta.id == this.certificate_item.meta_data_id);
      }
      this.form.items.push({
        name: this.certificate_item.name,
        meta_data_id: this.certificate_item.meta_data_id,
        top: 0,
        left: 0,
        width: null,
        font_size: null,
        font_weight: null,
        text_align: 'left',
        color: 'black',
        line_height: null,
        data: meta_data_index != null ? this.formatMetaData[meta_data_index].default_data : null,
        type: meta_data_index != null ? (this.formatMetaData[meta_data_index].type == 'Meta Data' ? 1 : this.formatMetaData[meta_data_index].type == 'Text Paragraph' ? 2 : 3) : this.certificate_item.type
      });
      this.certificateModal(false);

    },
    getFormat() {
      axios
          .get('/app/certificate-format/' + this.id)
          .then((res) => {
            this.format = res.data.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },
    certificateModal(status) {
      if (this.certificate_item.name != null || this.certificate_item.meta_data_id != null) {
        this.clearModalData();
        this.certificate_model = status;
      } else this.certificate_model = status;
    },

    getMetaData() {
      axios
          .get('/app/certificate-format/meta-data/' + this.id)
          .then((res) => {
            this.formatMetaData = res.data.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    getTemplateItems() {
      axios
          .get('/app/certificate/template-items/' + this.id)
          .then((res) => {
            this.form.items = res.data.items;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    templateGenerate() {
      this.form
          .post('/app/certificate/template-generate/' + this.id)
          .then((res) => {
            this.s(res.data.message);
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    clearModalData() {
      this.certificate_item.name = null;
      this.certificate_item.meta_data_id = null;
      this.certificate_item.type = null;
    },

    deleteItem(index) {
      this.form.items.splice(index, 1);
    }
  },

  computed: {
    permission() {
      return this.$store.getters.getPermissions.format;
    },
  },
};
</script>